.subscribe {
  &-phone-icon {
    background-image: url("../../images/icons/phone-locked-2.png");
    width: 100%;
    height: 100%;
    background-position: 50% 55%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-bg-container {
    background-color: #fff;
    background-position: 55% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 0.9375rem 1.875rem -0.5625rem rgb(69 143 246 / 18%);
    height: 10rem;
    margin: 0 auto;
    padding: 2rem;
    width: 10rem;    

  }

  &-phone-form {
    max-width: 25rem;
    margin: 0 auto;
  }

  &-mailing-form {
    max-width: 50rem;
  }
}

div.my-3.subscribe-phone-form > form > div.form-group > input {
  width: 10rem !important;
  margin: 0 auto;
}

