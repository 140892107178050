.jumbotron {
  background: linear-gradient(180.88deg, #eaf2fe  0.77%, rgba(240, 246, 255, 0) 161.26%);
  // padding-top: 5rem !important;
  // padding-bottom: 2rem !important;
  /*background-image: url("~images/CTA-banner__BG-logo.png");*/
  background-size: cover;
  opacity: 1;

    .title {
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 3.4375rem;
    }

    .subtitle {
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }    

  .panel-2 {
    // background-image: url("~images/couple-teledoc.jpg");
    // background-size: 100%;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    // max-width: 50rem;
    // min-height: 38rem;
    //max-height: 450px;
    /*background-size: 129%;*/

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

}

.woman-on-couch {
  width: 100%;
}

.recommended
{
  border-radius: 1.6rem;
  border: solid 1px $green;
  background-color: $white;
  color: $green;
  font-size: 1.375rem;
  line-height: 1.67875rem;
  font-weight: bold;
  // padding: 0.5rem 1.5rem;
}


.recommended-orange
{
  border-radius: 1.6rem;
  border: solid 1px $orange;
  background-color: $white;
  color: $orange;
  font-size: 1.375rem;
  line-height: 1.67875rem;
  font-weight: bold;
}
.envelope {
  img {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.fsa {
  width: fit-content !important;
  margin: 0 auto;
  img {
    margin-right: 0.625rem;
  }
}

.btn-activate-home {
  padding: 1rem 1.5rem !important;
  width: 60%;
}

.btn-activate-home:hover {
  background: $orange !important;
}

.couple-image {
  background-image: url("~images/CTA-banner__BG-logo.png");
  background-size: cover;
  img {
    width: 100%;
  }
}

.preventable-section {
  background-color: $lighterblue;

  .title {
    font-size: 3.375rem; 
    line-height: 4.7275rem;
  }

}

.acg-recommend-container {
  /*margin-top: 10rem !important;*/
}

.acg-recommend {
  color: $white;
  border-radius: 1.0rem;
  background-color: $primary;
  font-size: 1.75rem;
  /*padding: 2rem 1.5rem 2rem 30rem;*/
  text-align: left;
  font-weight: 400;
  line-height: 2.5rem;
  /*position: relative;*/

  .home-test-kit-image {
    /*text-align: left;
    position: absolute;
    left: 2rem;
    bottom: 1rem;*/
    img {
      /*width: 32%;*/
      width: 100%;
      image-rendering: optimizeQuality;
    }
  }  
}


.digital-results-section {
  background-color: $lighterblue;
  .col {
    background-image: url("~images/digital-result__BG-logo.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 20%;
  }
}

.digital-results {
  img {
    width: 100%;
  }
}

.faq-section {
  .doc-image {
    img {
      width: 100%;
    }
  }
}

.bottom-activation-section {
  background: linear-gradient(180deg, $off-white 0%, rgba(247, 251, 255, 0.47) 100%);
  border-radius: 2.25rem;

  .title {
    font-size: 3.44rem;
    line-height: 3.9rem;
  }

  .col {
    background-image: url("~images/footer-top__CTA-shapes.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.pricing {
  background-color: $lighterblue;
}


.insurance-image {
  width: 100%;
}

.virtual-visit {
  &-item {
    width: 50%;
    min-width: 400px;
  }

  &-left {
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .jumbotron {
    .title {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    .subtitle {
      font-size: 0.9rem;
      line-height: 1.125rem;
      margin-bottom: 1.2rem;
    } 
    
    .panel-2 {
      max-width: 800px;
      min-height: 200px;
      width: 100%;
    }
  }

  .acg-recommend {
    font-size: 1.5rem;
    line-height: 1.75rem;

    img {
      width: 70% !important;
    }
  }

  .recommended {
  }

  .preventable-section {
    .title {
      font-size: 2.0rem;
      line-height: 2.6rem;
    }
  
  }  
  
  .bottom-activation-section {
  
    .title {
      font-size: 2.0rem;
      line-height: 2.6rem;
    }
  }

  .btn-cta {
    font-size: 16px;
  }

}
