.contact {

  min-height: 50vh;

  &-section {
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.625rem;
    border: solid 1px #d0e2fc;
    color: #535f70;
    background: linear-gradient(180deg, #F5F9FF 0%, rgba(247, 251, 255, 0.13) 100%);
    background-color: #f1f7ff;
    flex: 1 1 1rem;
    min-width: 14rem;
  }
}