.calendar {
  &-table {
  }

  td.calendar-day-block  {
    max-width: 12.5rem;
    min-width: 7.3125rem;
    min-height: 6.5625rem;
  }

  &-month-name {
    font-size: 2rem;
    font-weight: bold;
  }

  &-block {
    min-height: 5rem;
  }

  &-day {
    text-align: right;
  }

  &-day-content {
    max-width: 12.5rem;
    min-height: 6.5625rem;


    &-timeslot {
      font-size: 0.8125rem;
      padding: 0.2125rem;

      &-1 {
        background-color: $primary;
      }
      &-2 {
        background-color: $yellow;
      }
      &-3 {
        background-color: $green;
        text-decoration: none;
        color: $white;
      }
      &-4 {
        background-color: $red;
        text-decoration: line-through;
        color: $white;
      }
      &-10 {
        background-color: $blue; // change to gray.
        text-decoration: line-through;
        color: $white;
      }
      &-20 {
        background-color: $red;
        text-decoration: line-through;
        color: $white;
      }
      &-21 {
        background-color: $red;
        text-decoration: line-through;
        color: $white;
      }
    }

  }
}

/*
enum AppointmentStatusEnum {
 OPEN                                    = 1,
  PRACTITIONER_PENDING_SMS_RESPONSE       = 2,  make this YELLOW
  SCHEDULE_PRACTITIONER_CONFIRMED         = 3,  green (not dark, not light traffic light)
  SCHEDULE_PRACTITIONER_NOT_AVAILABLE     = 4,

  FINISHED                                = 10,
  CANCELED                                = 20,
  CANCELLING                              = 21,
}*/