.verification-form {


  &-field {
    width: 2.25rem;
    height: 2.25rem;
    text-align: center;
    font-size: 1.25rem;
    border: solid 1px #1b1b1b;
    border-radius: 5px;
  }


}