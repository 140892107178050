.icon {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background: $primary;
  background-repeat: no-repeat;
  background-size: 40% 40%;
  background-position: center;
}

.icon-cart {
  background-image: url('~images/icons/nav-cart.png');
}

.icon-account {
  background-image: url('~images/icons/nav-user.png');
}