// body {
//   font-family: Calibri, Arial, sans-serif;
// }

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

html, body {
  font-family: 'Manrope', sans-serif;
}

.main-container {
  max-width: 1440px;
  margin: 0 auto;
}

.main-nav {
  position: fixed;
  top: 0; left: 0;

}

.header-logo {
  width: 15rem;

  img {
    width: 100%;
  }
}

.btn {
  border-radius: 80px !important;
}

.btn-cta {
  font-weight: 700;
  color: $white !important;
  border-radius: 2rem;
  padding: 0.8rem 2rem;
  box-shadow: 0 0.2rem 0.2rem 0rem $lightgray;
  box-sizing: border-box;
  background-color: $blue;
}

.btn-cta:hover {
  background-color: $blue !important;
  border-color: $white !important;
}


.btn-activate {
  line-height: 1.525rem;
  font-size: 1.25rem;
}

.menu-item {
  font-size: 0.95rem;
  font-weight: 400;
  color: $primary-dark !important;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.mobile-activate-menu-item {
  background-color: $orange;
  color: $white;
  font-weight: bold;
  width: 100%;
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem
}

.rounded-box {
  border-radius: 1rem;
  padding: 1.0rem 2.0rem;
  background-color: $white;
}

input[name="collectionDate"] {
  display: none;
}

.error {
  color: $red;
}


.unset-line-height {
  line-height: unset !important;
}


.bg-lightergray {
  background-color: $lightergray;
}

.bg-green {
  background-color: $green;
}

.bg-white {
  background-color: $white !important;
}

.bg-lighterblue {
  background-color: $lighterblue;
}

.margin-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.appt-checked {
  color: $white;
  background-image: url('~images/green-check.png');
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: left !important;
  width: 36px;
  height: 50px;
  margin-top: 15px;
}

.bg-big-white-circles {
  background-image: url("~images/banner-BG__Logo-white.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;  
}

.bg-dark-gray {
  background-color: $darkgray;
}

.bg-blue {
  background-color: $blue !important;
}

.card-rounded {
  border-radius: 1.375rem;
  box-shadow: 18px 49px 94px rgba(9, 22, 40, 0.06);
  border-radius: 1.375rem;
  padding: 0.625rem;
  margin: 0.625rem 0;
  box-sizing: border-box;
  border: solid 1px #458ff640;  
}

.telno {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 575px) {
  .header-logo {
    width: 15rem;
  }
}


@media screen and (max-width: 1192px) {
  .btn-activate {
    font-size: 0.92rem;
  }
}