$primary: #458ff6;
$secondary: #e45829;
$orange: #F26231;
$white: #ffffff;
$lightOrange:#dfbbb0;
$black: #000000;
$darkgray: #535F70;
$red: #fc6666;
$lightred: #f88894;
$lighterred: #f8a9b1;
$yellow: #dcdf5f;

// blues
$blue: #2E4CEB;
$darkblue: #03295f;
$darkerblue: #00142F;
$lightblue: #458ff6;
$lighterblue: #f1f7ff;
$skyblue: #d0e2fc;
$lightskyblue: #e4eefd;
$midskyblue: #EBF5FF;
$heavyskyblue: #2F80ED;

$gray: #535f70;
$lightgray: #b8b8b8;
$lightergray: #eeeeee;
$primary-dark: #03295f;
$off-white: #F5F9FF;
$breakpoint-small: 768px;


// greens
$darkgreen: #054238;
$lightgreen: #86f1e0;
$green: #0C8F11;

