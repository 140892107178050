.dark-blue {
  color: $darkblue;
}

.text-dark-gray, .dark-gray {
  color: $darkgray;
}

.light-blue {
  color: $lightblue;
}

.orange {
  color: $orange;
}

.light-orange {
  color: $lightOrange;
}

.text-red {
  color: $red;
}

.text-gray {
  color: $gray;
}

.text-green {
  color: $green;
}

.text-blue {
  color: $blue !important;
}

.text-gray {
  color: $gray !important;
}

.strong {
  font-weight: bold !important;
}

.strikethrough {
  text-decoration: line-through;
}

.primary-dark {
  color: $primary-dark
}

.large-text-1 {
  font-size: 2.625rem;
  line-height: 3.676875rem;
}

.large-text-2 {
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 4.7275rem;
}

.large-text-3 {
  font-size: 2.875rem;
  line-height: 3.509375rem;
}

.medium-text-1 {
  font-size: 1.625rem;
  line-height: 2.1875rem;
}

.medium-text-2 {
  font-size: 1.75rem;
  line-height: 2.30rem;
}

.small-text-1 {
  font-size: 0.90rem;
  line-height: 1.55rem;
}

.small-text-2 {
  font-size: 1.03125rem;
  font-weight: 400;
  line-height: 1.6rem;
}

.small-text-3 {
  font-size: 1.25rem;
}

.small-text-4 {
  font-size: 1.375rem;
}

.small-text-5 {
  font-size: 1.5rem;
}

.tiny-text-1 {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .large-text-1 {
    font-size: 1.275rem;
    line-height: 1.375rem;
  }

  .large-text-2 {
    font-size: 1.775rem;
    line-height: 2.0rem;
  }
  
  .large-text-3 {
    font-size: 2.0rem;
    line-height: 2.2rem;
  }

  .medium-text-1 {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .medium-text-2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}

  .small-text-3 {
    font-size: 1.0rem;
  }

  .small-text-4 {
    line-height: 1.7rem;
  }

  .small-text-5 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .smaller-text-1 {
    font-size: 0.5rem;
  }

  .smaller-text-2 {
    font-size: 0.6rem;
  }

  .smaller-text-3 {
    font-size: 0.7rem;
  }

  .smaller-text-4 {
    font-size: 0.8rem;
  }

  .smaller-text-5 {
    font-size: 0.9rem;
  }


}