.admin-layout > * {
  font-size: 0.875rem !important;  
}

.admin-layout {
  font-size: 1rem;

  .btn {
    border-radius: 5px !important;
    font-size: 0.875rem !important;
  }

  &-section {

    &-title {
      width: 100%;
      margin: 10px 0;
      padding: 10px;
      font-size: 1.5rem !important;
      background-color: $darkgray;
      color: $white;
      font-weight: 700;
    }

  }
}

