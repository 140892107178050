
.practitioner-card:hover {
  border: solid 1px #458ff6;
}

.practitioner-card {
  width: 18.75rem;
  min-height: 33.75rem;
  border-radius: 1.375rem;
  box-shadow: 18px 49px 94px rgba(9, 22, 40, 0.06);
  border-radius: 1.375rem;
  padding: 0.625rem;
  margin: 0.625rem 0;
  box-sizing: border-box;
  border: solid 1px #458ff640;


  &-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    text-align: center;
    margin: 15px 0 5px 0;
  }

  &-bio-link {
    font-weight: 800;
    font-size: 18px;
    line-height: 24.59px;
    text-align: center;
    color: $orange;
  }


  &-specialty {
    font-weight: 700;
    font-size: 16px;
    line-height: 22.4px;
    text-align: center;
    margin-top: 5px;
    color: $primary;
  }

  &-board-certified {
    font-weight: 700;
    font-size: 12px;
    line-height: 16.8px;
    text-align: center;
    position: relative;
    margin: 10px 0 15px 0;
  }

  &-board-certified::before {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANhSURBVHgBvZVvSBNhHMe/d7dNt7kllVaGtREzzLYyibReqBX0DxUyozQlAulVoG/KCINYBRHim0KEwqIU7FUhBK7ojYJtUpppUto/FNQy/7V58+52T3dbWtZNNxl+783d89zv+Tx/fr/vQxFJkDQ8PIySkhLwPA+TyYRIaGRkBB6PB01NTTAajYFG8lsul4ukpaWR7u5uEin19vb6x2xvb59ro/+dVXR0NCIllUo1u6g/baEEiqIIh8Ph324lxcTEICsrCxRFLTpWSED5DOx2+4L/lJeXo6ioCBEBJiUlwWq1wuv1KvbLK8zOzkYoCgmYnJyMuro6REI0llnLDgy6pU6nE7W1tQhXcqbKBpKZmRkeUHab0tJSLCZCRAhSmTFSRdBUYMNSUlKC/h8UqNfrIQiCYm3Jhbx9Vzo6x4H2IQ/s72jsXCXi/DY91ugoGIwMwgb29fWhoaFBsc9sNuMBZcWdjxT2x1M4l6zCJEdwtpXFgFvAy0NR2LFGHx4wNTUVNTU1in1t32Zw9dk0Hu6hUWDSobq6GrttVlzJ2YeCF15c6xFRa1R2pbCzlBV8OOzw4sQGGic3GUAk23vU2AiX04XYKDUa9urwfETEpdfswsDZs+I4bkGghqFh1Ig4tkmHiooKFBYW+ttbWlqQn5+PXmcrUmMJTEYNWJadN7asuS21WCwwGAwoKytDfHx8UOCp4mIJmg6WE6DVakHTgTnLxi7fDgzDSBnLwOcTYL9uR1xcHBITE+fiKfLX3TE4OOhPFLfbHRR4MCcPR/otKDTTuJWuB8/xyMjIQF5eHi5XVuLzFIutTTM4vTkK2V+fwmaz+b14TmQJejvpI6vqR8ndnnHC8xypqqoizc3NZJTlyIHmKZLr+EHGpgXF2HkrDFlSyJk2D+73iziQQGPXagqTXhH1n2Ywxqnx6qgeNqNyAYR0W/wnKQkK1okwqGm4WQE33xBsidOg2KKFT+Bh0QZP/iUBifRc7PKha1LE6igGHbkqPB4UcaHDJ88GGQkCjm/UKMYu6bb48JOga4KSsDTGvAI2rtCAYuRveTYUbr+f8XtsxIA3OiekcQOh63U01NL7WnWg1iQeWodFOIcWKfxQ9X2ax5MBYKXKhwQJZpKMWq5rcyztTyZbbMDc730hUMrHX/9+5VqcyZmKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    margin-left: -24px;
    background-size: contain;
  }

  &-icon {}

  &-avatar {
    width: 153px;
    height: 183px;
    margin: 10px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-children {}

  &-bio {

    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 18px 49px 94px rgba(9, 22, 40, 0.06);
    border-radius: 22px;
    border: solid 1px #458ff638;

    &-title {
      font-size: 26px;
      font-weight: 700;
      color: $primary;
    }

  }

  &-bio-title-container {}

  &-bio-about {
    font-weight: 400;
    font-size: 16px;
  }

  &-bio-insurance {
    font-weight: 700;
  }
}

.btn-make-appointment {
  font-weight: 700;
  background-color: $blue;
  font-size: 18px;
  line-height: 22px;
  color: $white;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 5px 20px rgba(109, 221, 252, 0.28);
  border-radius: 80px;
  text-align: center;
  width: 206px;
  height: 50px;
  display: inline;
  padding: 10px 20px;
}

.practitioner-wide-card {

  // width: 18.75rem;
  // min-height: 33.75rem;
  border-radius: 1.375rem;
  box-shadow: 18px 49px 94px rgba(9, 22, 40, 0.06);
  border-radius: 1.375rem;
  padding: 0.625rem;
  margin: 0.225rem 0;
  box-sizing: border-box;
  border: solid 1px #458ff640;

  &-avatar {
    max-width: 5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-title {
    font-size: 0.8rem;
    font-weight: bold;
    margin-left: 0.6rem;
  }

  &-specialty {
    font-size: 0.7rem;
    text-align: right;
    color: $primary;
    font-weight: 700;
  }
  
  &-board-certified {
    font-weight: 700;
    font-size: 0.65rem;
    line-height: 1rem;
    text-align: left;
    position: relative;
    left: 2rem;
  }

  &-board-certified::before {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANhSURBVHgBvZVvSBNhHMe/d7dNt7kllVaGtREzzLYyibReqBX0DxUyozQlAulVoG/KCINYBRHim0KEwqIU7FUhBK7ojYJtUpppUto/FNQy/7V58+52T3dbWtZNNxl+783d89zv+Tx/fr/vQxFJkDQ8PIySkhLwPA+TyYRIaGRkBB6PB01NTTAajYFG8lsul4ukpaWR7u5uEin19vb6x2xvb59ro/+dVXR0NCIllUo1u6g/baEEiqIIh8Ph324lxcTEICsrCxRFLTpWSED5DOx2+4L/lJeXo6ioCBEBJiUlwWq1wuv1KvbLK8zOzkYoCgmYnJyMuro6REI0llnLDgy6pU6nE7W1tQhXcqbKBpKZmRkeUHab0tJSLCZCRAhSmTFSRdBUYMNSUlKC/h8UqNfrIQiCYm3Jhbx9Vzo6x4H2IQ/s72jsXCXi/DY91ugoGIwMwgb29fWhoaFBsc9sNuMBZcWdjxT2x1M4l6zCJEdwtpXFgFvAy0NR2LFGHx4wNTUVNTU1in1t32Zw9dk0Hu6hUWDSobq6GrttVlzJ2YeCF15c6xFRa1R2pbCzlBV8OOzw4sQGGic3GUAk23vU2AiX04XYKDUa9urwfETEpdfswsDZs+I4bkGghqFh1Ig4tkmHiooKFBYW+ttbWlqQn5+PXmcrUmMJTEYNWJadN7asuS21WCwwGAwoKytDfHx8UOCp4mIJmg6WE6DVakHTgTnLxi7fDgzDSBnLwOcTYL9uR1xcHBITE+fiKfLX3TE4OOhPFLfbHRR4MCcPR/otKDTTuJWuB8/xyMjIQF5eHi5XVuLzFIutTTM4vTkK2V+fwmaz+b14TmQJejvpI6vqR8ndnnHC8xypqqoizc3NZJTlyIHmKZLr+EHGpgXF2HkrDFlSyJk2D+73iziQQGPXagqTXhH1n2Ywxqnx6qgeNqNyAYR0W/wnKQkK1okwqGm4WQE33xBsidOg2KKFT+Bh0QZP/iUBifRc7PKha1LE6igGHbkqPB4UcaHDJ88GGQkCjm/UKMYu6bb48JOga4KSsDTGvAI2rtCAYuRveTYUbr+f8XtsxIA3OiekcQOh63U01NL7WnWg1iQeWodFOIcWKfxQ9X2ax5MBYKXKhwQJZpKMWq5rcyztTyZbbMDc730hUMrHX/9+5VqcyZmKAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    width: 1.2rem;
    height: 1.2rem;
    margin-left: -1.5rem;
    background-size: contain;
  }

  &-children {

    .daycard {
      margin-left: 0.3rem;
      align-items: baseline;

    }

    .daycard-datetime {
      font-size: 0.6875rem;
      margin-left: 0.6rem;
      margin-top: 0.4rem;
    }

    .btn-daycard {
      font-size: 0.6875rem;
      font-weight: 700;
      width: 20%;
      cursor: pointer;
      border: solid 2px;
      text-align: center;
      margin: 0;
      padding: 0.5rem;
      flex-grow: 3;   
    }

    .btn-daycard-show-more {
      font-size: 0.6rem;
    }
  }
}



@media screen and (max-width: 768px) {
  .practitioner-card {
    &-bio {
      &-title {
        font-size: 20px;
      }
    }
  }

  .btn-make-appointment {
    font-size: 14px;
    line-height: 22px;
    padding: 8px 10px;
    height: auto;
    width: auto;
  }
}