.daycard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn {

  &-daycard {
    font-size: 14px;
    font-weight: 800;
    width: 30%;
    cursor: pointer;
    border: solid 2px;
    text-align: center;
    margin: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
      
    &-light {
      background-color: $white;
      color: $primary;
    }

    &-dark {
      background-color: #297BFD;
      color: $white;
      border-color: #297BFD;
    }

    &-dark:hover {
      background-color: $white;
      border: solid 2px $primary;
      box-sizing: border-box;
      color: $primary;
    }

    &-show-more {
      font-weight: 700;
      font-size: 16px;
      line-height: 18.65px;
      color: $black;
      margin: 5px auto;
    }

  }

}