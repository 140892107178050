.footer {
  background-color: $darkblue;
  position: relative;

  p {
    color: $white;
    opacity: 0.7;
  }
  
  &-img img {
      width: 100%;
  }

  &-map img {
      border-radius: 2rem;
      width: 100%;
  }

  .col, .col-auto {
    color: $white;
  }

  &-link-header {
    font-size: 1.5rem;
    line-height: 2.0rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  &-link-item {
    font-size: 0.95rem;
    line-height:1.20rem;
    margin-bottom: 1rem;
    font-weight: 700;
    
    a {
      color: $white;
      text-decoration: none;
    }
  }

  &-all-rights{
    font-size: 0.9375rem;
    line-height:1.33125rem;
  }

  &-map {
    max-width: 325px;
    margin: 0 auto;
  }

}

.bottom-footer {
  background-color: $darkerblue;
  padding: 1rem;

  &-item {
    color: $white;
    font-size: 0.80rem;

    a {
      color: $white;
      text-decoration: none;
    }
  }
}

.footer.bg-big-white-circle {
}

.footer .bg-big-white-circle::before {
//   background-image: url("~images/banner-BG__Logo-white.png");
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position-x: right;
//   opacity: 0.5;
//   content: "";
//   position: absolute;
//   top: 20%;
//   width: 100%;
//   height: 100%;
//   left: 10%;
 }

@media screen and (max-width: 768px) {
  .footer {
    p {
      font-size: 0.9rem;
    }

    &-link-header {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }    

    &-link-item {
      font-size: 0.90rem;
      line-height:1.50rem;
    }    
  }
}