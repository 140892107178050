.step-card {

  text-align: center;
  border-radius: 1.375rem;
  border-radius: 22px;
  border: solid 1px $skyblue;
  padding: 4rem 2rem !important;
  height: 100%;
  color: $gray;
  background: linear-gradient(180deg, $off-white 0%, rgba(247, 251, 255, 0.13) 100%);
  background-color: $lighterblue;

  .title {
    color: $darkblue;
  }

  .step-card-icon {
    border-radius: 50%;
    width: 6.5rem;
    height: 6.5rem;
    background: $white;
    background-repeat: no-repeat;
    background-position: 55% 50%;
    box-shadow: 0 0.9375rem 1.875rem -0.5625rem rgba(69, 143, 246, 0.18);
    padding: 2rem;
    margin: 0 auto;
  }

  .step-card-description {

  }

  .step-1-icon {
    background-image: url("~images/icons/register\ your\ kit.png");
  }

  .step-2-icon {
    background-image: url("~images/icons/Collect\ sample\ at\ home.png");
  }

  .step-3-icon {
    background-image: url("~images/icons/online-result.png");
  }

  .certified-lab-icon {
    background-image: url("~images/icons/certified\ lab.png");
  }

  .physician-oversight-icon {
    background-image: url("~images/icons/Physician.png");
  }

  .private-secure-icon {
    background-image: url("~images/icons/secure\ data.png");
  }

  .easy-to-understand-icon {
    background-image: url("~images/icons/easy\ to\ understand.png");
  }

  .personalized {
    background-image: url("~images/icons/Personalized.png");
  }

  .actionable {
    background-image: url("~images/icons/Actionable.png");
  }
}

.step-card:hover {
  border: solid 1px $lightskyblue;
  background: unset;
  background-color: $white;

  .title {
    color: $lightblue;
  }
}

.digital-result-card {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.39) 100%);
  border-radius:1.5rem;
  border: 0.1321rem solid $white;
  box-shadow: 0 1.4375rem 2.375rem rgba(3, 41, 95, 0.04);
  backdrop-filter: blur(0.4rem);
}

.digital-result-card:hover {
  border: solid 0.1321rem $white;
}

@media screen and (max-width: 768px) {
  .step-card {
    padding: 1.0rem 1.0rem !important;
  }
}