.practitioner {
  width: 300px;
  
  &-name {
    font-weight: bold;

  }

  &-avatar {
    img {
      width:3.125rem;
    }
  }

  &-specialty {

  }
}

.appointment-wizard {
  background: $lighterblue;
  min-height: 50vh;
  border-radius: .3125rem;
}

.appt-viewport {
  position: relative;
  border-radius: 1rem;
}

.appt-viewport-collapsed {
  // max-height: 500px;
  overflow: hidden;
  opacity: 0.5;
  background: linear-gradient(#ffffff, rgba(201, 201, 201, 0.6509803922));
}

.select-insurance-carrier {
  padding: 0.325rem;
  border-radius: 0.25rem;
  border-color: #d5d5d5;
}

.make-appointment-now {
  text-decoration: none;
}
.make-appointment-now:hover {
  text-decoration: underline !important;
  color: $lightOrange;
}

.appt-phase-number {
}

.appt-phase-cell {
  border: none;
  height: 5rem;
  width: 20%;
}

.appt-phase-title {
  background-color: $white;
  font-size: 1.0rem;
}

.btn-circle {
  width: 2rem;
  height: 2rem;
  background-color: white;
  box-shadow: 0px 0px 0px 4px #6e6e6e;
  border-radius: 2rem;
  margin: 11px auto;
  padding: 3px 0 0 0;
}


.btn-circle {
  color: $black;
  //background-image: url('~images/green-check.png');
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: left !important;

  &.checked {
    background-color: $green;
    color: $white;
  }

  &.current {
    background-color: $lightgreen;
    color: $white;
  }
}

.btn-appt {
  border-radius: 80px !important;
  padding: 14px 60px;
  gap: 10px;

  &-change {
    background-color: #9FC4FE !important;
  }
}


@media screen and (max-width: 768px) {
  .appt-phase-title {
    font-size: 0.80rem;
  }

  .appt-viewport-collapsed {
    max-height: unset;
  }
}
